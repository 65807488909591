import { useParams } from '@tanstack/react-router'
import { useFlag } from '@unleash/proxy-client-react'
import { isAfter } from 'date-fns'
import { useEffect, useRef } from 'react'
import { Slide, toast } from 'react-toastify'

import { NotificationToast } from '@/features/notification/components/NotificationToast'
import { useMarkNotificationsAsRead } from '@/features/notification/hooks/useMarkNotificationsAsRead'
import { useViewerWithNotifications } from '@/features/notification/hooks/useViewerWithNotifications'
import { FetchViewerWithNotificationsQuery } from '@/gql/generated/graphql'

export type ViewerNotification =
  FetchViewerWithNotificationsQuery['viewer']['notifications'][0]

export const useNotificationToast = (isNotificationSidebarOpened: boolean) => {
  const isUnreadIndicatorEnabled = useFlag(
    'pro-1061-isunread_notification_status_feature'
  )
  const { mutate: markNotificationsAsReadMutation } =
    useMarkNotificationsAsRead()

  const newestNotification = useRef<ViewerNotification>()
  const isLastRequestWasEmpty = useRef(false)

  const { taskId } = useParams({ strict: false })

  const {
    viewer: { notifications },
  } = useViewerWithNotifications(true)

  useEffect(() => {
    let newestIncomingNotification: ViewerNotification | undefined
    for (const notification of notifications) {
      if (
        !newestIncomingNotification ||
        isAfter(notification.createdAt, newestIncomingNotification.createdAt)
      ) {
        newestIncomingNotification = notification
      }
    }

    const previousLastNotification = newestNotification.current
    if (isNotificationSidebarOpened) {
      isLastRequestWasEmpty.current = notifications.length === 0
      newestNotification.current = newestIncomingNotification
      return
    }

    const newNotifications = notifications.filter(
      (notification) =>
        (isLastRequestWasEmpty.current ||
          (previousLastNotification &&
            isAfter(
              notification.createdAt,
              previousLastNotification.createdAt
            ))) &&
        taskId !== notification.task?.id
    )

    isLastRequestWasEmpty.current = notifications.length === 0
    newestNotification.current = newestIncomingNotification

    for (const notification of newNotifications) {
      toast(NotificationToast, {
        autoClose: 5000,
        className: 'w-[28rem] !rounded-2xl',
        closeButton: false,
        data: {
          isUnreadIndicatorEnabled,
          markNotificationsAsReadMutation,
          notification,
        },
        hideProgressBar: true,
        pauseOnHover: true,
        transition: Slide,
      })
    }
  }, [
    isNotificationSidebarOpened,
    isUnreadIndicatorEnabled,
    markNotificationsAsReadMutation,
    notifications,
    taskId,
  ])
}
