import { Link } from '@tanstack/react-router'
import { FC, useCallback } from 'react'
import { ToastContentProps } from 'react-toastify'

import { Button } from '@/components/ui/button/Button'
import { Notification } from '@/features/notification/components/Notification'
import { useMarkNotificationsAsRead } from '@/features/notification/hooks/useMarkNotificationsAsRead'
import { ViewerNotification } from '@/features/notification/hooks/useNotificationToast'

interface NotificationToastProperties {
  notification: ViewerNotification
  isUnreadIndicatorEnabled: boolean
  // have to pass it because toast can't read the api context
  markNotificationsAsReadMutation: ReturnType<
    typeof useMarkNotificationsAsRead
  >['mutate']
}

const OldNotificationToast: FC<
  ToastContentProps<NotificationToastProperties>
> = ({ closeToast, data: { notification } }) => {
  return (
    <div
      className="flex flex-col gap-3"
      data-testid={`notification-toast-${notification.id}`}
    >
      <Notification notification={notification} hideTimestamp />

      <div className="flex justify-end gap-2">
        <Button
          variant="naked"
          className="border-film-subtle"
          onClick={closeToast}
        >
          Dismiss
        </Button>
        {notification.task?.id && (
          <Button asChild className="text-mono-ink-strong">
            <Link to={`/all-work/${notification.task.id}`} onClick={closeToast}>
              Show
            </Link>
          </Button>
        )}
      </div>
    </div>
  )
}

OldNotificationToast.displayName = 'OldNotificationToast'

const NotificationStatusFeatureNotificationToast: FC<
  ToastContentProps<NotificationToastProperties>
> = ({
  closeToast,
  data: { markNotificationsAsReadMutation, notification },
}) => {
  const handleShowTask = useCallback(() => {
    markNotificationsAsReadMutation([notification.id])
    closeToast()
  }, [closeToast, markNotificationsAsReadMutation, notification.id])

  return (
    <div
      className="flex flex-col gap-3"
      data-testid={`notification-toast-${notification.id}`}
    >
      <Notification notification={notification} hideTimestamp />

      <div className="flex justify-end gap-2">
        <Button
          variant="naked"
          className="border-film-subtle"
          onClick={closeToast}
        >
          Dismiss
        </Button>
        {notification.task?.id && (
          <Button asChild className="text-mono-ink-strong">
            <Link
              to={`/all-work/${notification.task.id}`}
              onClick={handleShowTask}
            >
              Show
            </Link>
          </Button>
        )}
      </div>
    </div>
  )
}

NotificationStatusFeatureNotificationToast.displayName =
  'NotificationStatusFeatureNotificationToast'

export const NotificationToast: FC<
  ToastContentProps<NotificationToastProperties>
> = (properties) => {
  if (properties.data.isUnreadIndicatorEnabled) {
    return <NotificationStatusFeatureNotificationToast {...properties} />
  }

  return <OldNotificationToast {...properties} />
}

NotificationToast.displayName = 'NotificationToast'
